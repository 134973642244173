import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

const LookingInspiration = () => {
  return (
    <div className="hero bg-base-100 py-10 drop-shadow-md">
      <div className="hero-content flex-col lg:flex-row">
        <div>
          <ReactSVG
            className="w-[40rem]"
            src="https://res.cloudinary.com/lsoe/image/upload/v1715352722/images/Looking%20for%20insp.svg"
          />
        </div>

        <div className="lg:pl-10">
          <h1 className="text-2xl font-bold uppercase text-slate-700 pb-3 text-center">
            Career Inspiration?
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <Link
              to="/university-courses"
              className="btn btn-accent btn-outline hover:text-white"
            >
              <button>University Courses</button>
            </Link>
            <button className="btn btn-accent btn-outline hover:text-white">
              Level-3 Free Courses
            </button>
            <Link
              to="/services-we-provide"
              className="btn btn-accent btn-outline hover:text-white"
            >
              <button>Empolyment Fields</button>
            </Link>
            <Link
              to="/career-hub"
              className="btn btn-accent btn-outline hover:text-white"
            >
              <button>Career Advice</button>
            </Link>
          </div>
          <br />
          {/* <Link to="/contact-lsoe-hiring-nexus">
            <button className="bg-accent hover:bg-sky-500 text-white font-bold py-2 px-4 border-b-4 border-info-700 rounded">
              Contact LSOE
            </button>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default LookingInspiration;
