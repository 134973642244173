import React from "react";
import Administration from "./Categories/Administration";
import CleaningHousekeeping from "./Categories/CleaningHousekeeping";
import HealthCare from "./Categories/HealthCare";
import HotelHospitality from "./Categories/HotelHospitality";
import Security from "./Categories/Security";

const ServiceWeProvideDetails = () => {
  return (
    <div className="bg-base-100">
      <h2 className="capitalize text-center font-bold text-slate-700 pt-10 text-3xl">
        Categories of Empolyment Field We Cover
      </h2>
      <div className=" container grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2   gap-3 mx-auto mt-3 py-10">
        <CleaningHousekeeping />
        <HealthCare />
        <HotelHospitality />
        <Security />
        <Administration />
      </div>
    </div>
  );
};

export default ServiceWeProvideDetails;
