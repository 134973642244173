import React from "react";
import ContactForm from "../../components/AdmissionContactCompo/ContactForm";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import AdmissionContacOfficeImage from "../../components/AdmissionContactCompo/AdmissionContacOfficeImage";
import EmploymentContactBanner from "../../components/EmploymentContactCompo/EmploymentContactBanner";

const ContactEmploymentHub = () => {
  return (
    <div>
      <EmploymentNav />
      <EmploymentContactBanner />
      <ContactForm />
      <AdmissionContacOfficeImage />
      <EmploymentFooter />
    </div>
  );
};

export default ContactEmploymentHub;
