import React from "react";
import { Link } from "react-router-dom";

const Security = () => {
  return (
    <div className="card bg-gray-200 shadow-xl">
      <figure>
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1675794315/images/security_pxdntv.png"
          alt="Security"
          className="max-w-sm"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title">Security</h2>
        <ul className="py-10">
          <li className="list-disc list-inside">Security Guard</li>
          <li className="list-disc list-inside">
            Residential/Private Security
          </li>
          <li className="list-disc list-inside">Official/Bank Security</li>
          <li className="list-disc list-inside">Industrial Security</li>
        </ul>
        <div className="card-actions justify-center">
          <Link to="/find-a-job">
            <button className="btn btn-accent text-white">
              Submit Your Application
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Security;
