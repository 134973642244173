import React from "react";
import PrivacyPolicyBanner from "../../components/PrivacyPpolicyCompo/PrivacyPolicyBanner";
import PrivacyPolicyDetails from "../../components/PrivacyPpolicyCompo/PrivacyPolicyDetails";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";

const PrivacyPolicy = () => {
  return (
    <div>
      <EmploymentNav />
      <PrivacyPolicyBanner />
      <PrivacyPolicyDetails />
      <EmploymentFooter />
    </div>
  );
};

export default PrivacyPolicy;
