import React from "react";

const PrivacyPolicyDetails = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-lg font-normal">
              At LSOE Pvt. Ltd. we take the privacy of our users seriously. We
              understand that your personal information is important to you and
              we are committed to protecting it. This Privacy Policy outlines
              the types of personal information we collect and how we use,
              disclose,and protect that information.
              <br />
              <br />
              <strong>Information Collection:</strong> We collect personal
              information from users when they register for an account, fill out
              a form, or use our services. The types of information we collect
              may include name, email address, phone number, and educational
              background. Use of Information: We use the personal information we
              collect to provide and improve our services, to communicate with
              users, and to comply with legal obligations. We may also use the
              information to personalize the user's experience on our website
              and to send promotional emails or messages.
              <br />
              <br />
              <strong>Disclosure of Information:</strong> We may disclose
              personal information to third parties in certain circumstances.
              For example, if required by law, or to protect the rights and
              safety of LSOE, our users, or others.
              <br />
              <br />
              <strong>Data Security:</strong> We take appropriate measures to
              protect personal information from unauthorized access, use, or
              disclosure. However, no security measure is 100% effective and we
              cannot guarantee the security of your personal information.
              Changes to Privacy Policy: We reserve the right to change this
              Privacy Policy at any time. Users should review this policy
              periodically to stay informed about how we are protecting their
              personal information. If you have any questions about this Privacy
              Policy, please contact us at{" "}
              <a className="link link-hover" href="mailto:info@lsoe.org.uk">
                info@lsoe.org.uk
              </a>
              <br />
              <br />
              By using our website and services, you consent to the collection,
              use, and disclosure of your personal information as described in
              this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyDetails;
