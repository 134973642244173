import React from "react";
import { Link } from "react-router-dom";

const UniversityAndCoursesBanner = () => {
  return (
    <div
      className="hero h-[50vh] drop-shadow-sm bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1715244032/images/Uni%20list%20cover.jpg")`,
      }}
    ></div>
  );
};

export default UniversityAndCoursesBanner;
