import React from "react";
import ModernSlaveryPolicyBanner from "../../components/ModernSlaveryPolicyCompo.js/ModernSlaveryPolicyBanner";
import SlaveryPolicy from "../../components/PrivacyPpolicyCompo/SlaveryPolicy";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";

const ModernSlaveryPolicy = () => {
  return (
    <>
      <EmploymentNav />
      <ModernSlaveryPolicyBanner />
      <SlaveryPolicy />
      <EmploymentFooter />
    </>
  );
};

export default ModernSlaveryPolicy;
