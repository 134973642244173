import React from "react";
import JotFormCompo from "../../components/ApplyNowLeadCompo/JotFormCompo";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const ApplyNow = () => {
  return (
    <div>
      <EmploymentNav />
      <JotFormCompo />
    </div>
  );
};

export default ApplyNow;
