import React, { useEffect } from "react";
import CalendlyBooking from "../../components/CareerHubCompo/CalendlyBooking";
import CareerHubCompoBanner from "../../components/CareerHubCompo/CareerHubCompoBanner";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";

const CareerHub = () => {
  useEffect(() => {
    document.title = "Career Hub - LSOE";
  }, []);
  return (
    <>
      <EmploymentNav />
      <CareerHubCompoBanner />
      <CalendlyBooking />
      <EmploymentFooter />
    </>
  );
};

export default CareerHub;
