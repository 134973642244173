import React from "react";
import { Link } from "react-router-dom";

const TrendingJobsCompo = () => {
  return (
    <div
      className="hero pt-10 bg-green-100"
      // style={{
      //   backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1714739981/images/find%20a%20talent.png")`,
      // }}
    >
      <div>
        <figure>
          <img
            src="https://res.cloudinary.com/lsoe/image/upload/v1714739981/images/find%20a%20talent.png"
            alt="Album"
          />
        </figure>
      </div>
      <div className=" container flex justify-around align-bottom px-8 mt-[15rem] lg:flex md:hidden sm:hidden">
        <div>
          <Link to="/find-a-job">
            <button className="btn btn-accent text-white">Find A Job</button>
          </Link>
        </div>
        <div>
          <Link to="/find-a-talent">
            <button className="btn btn-accent text-white">Find A Talent</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TrendingJobsCompo;
