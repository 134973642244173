import React from "react";
import CountUp from "react-countup";

const HBottomArea = () => {
  return (
    <div className="bg-green-300 z-0">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 py-10 mx-10">
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-green-200 drop-shadow-md rounded-md">
          <div className="card-body text-center">
            <h2 className="text-accent font-bold text-[2rem]">
              <CountUp end={2013} duration={3} />
            </h2>
            <p className="text-black font-medium">
              Active since, We are providing career guideline
            </p>
          </div>
        </div>
        {/* card one end   */}
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-green-200 drop-shadow-md rounded-md">
          <div className="card-body text-center">
            <p className="text-accent font-bold text-[2rem]">
              <span>
                <CountUp end={99} duration={3} />
              </span>
              %
            </p>
            <p className="text-black font-medium">
              Admitted university after completing our free Level-3 courses.
            </p>
          </div>
        </div>
        {/* card one end   */}
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-green-200 drop-shadow-md rounded-md">
          <div className="card-body text-center">
            <h2 className="text-accent font-bold text-[2rem]">
              <span>
                <CountUp end={96} duration={3} />
              </span>
              %
            </h2>
            <p className="text-black font-medium">
              of our clients got their dream job after completing graduation.
            </p>
          </div>
        </div>
        {/* card one end   */}
      </div>
    </div>
  );
};

export default HBottomArea;
