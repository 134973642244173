import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCrZkV6PPIDF19eFx6WSkGES59D8_BV8p0",
  authDomain: "lsoe-hiring-nexus.firebaseapp.com",
  projectId: "lsoe-hiring-nexus",
  storageBucket: "lsoe-hiring-nexus.appspot.com",
  messagingSenderId: "24989394493",
  appId: "1:24989394493:web:45e3027896c53921d26c54",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;
