import React from "react";
import { PopupButton } from "react-calendly";

const CalendlyBooking = () => {
  return (
    <div className="drop-shadow-md">
      <div className="hero bg-base-100 py-10">
        <div className="hero-content flex-col lg:flex-row ">
          <div className="">
            <div className="flex flex-col w-full">
              <button className="btn text-white btn-accent">
                <PopupButton
                  url="https://calendly.com/lsoehiringnexus/30min"
                  rootElement={document.getElementById("root")}
                  text="30 Min Free Consultation Session!"
                />
              </button>
              <div className="divider"></div>
              <button className="btn text-white btn-accent">
                <PopupButton
                  url="https://calendly.com/lsoehiringnexus/30min"
                  rootElement={document.getElementById("root")}
                  text="1 Hour Paid Consultation Session!"
                />
              </button>
            </div>
          </div>

          <div className="lg:pl-10">
            <img
              src="https://res.cloudinary.com/lsoe/image/upload/v1678912516/Booking_r2p33g.png"
              className="max-w-lg w-[80%] rounded"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendlyBooking;
