import React from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useForm } from "react-hook-form";
import Select from "react-select";

const EmpoyementHubBanner = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const optionsOne = [
    {
      value: "area_of_job_administration",
      label: "Area of Job Administration",
    },
    { value: "project_manager", label: "Project Manager" },
    { value: "public_relation_manager", label: "Public Relation Manager" },
    { value: "chartered_accountants", label: "Chartered Accountants" },
    { value: "accountant_cashier", label: "Accountant/Cashier" },
    { value: "secretary_executives", label: "Secretary/Executives" },
    { value: "clerk_typist_receptionist", label: "Clerk/Typist/Receptionist" },
    {
      value: "sales_personnel_store_keeper",
      label: "Sales Personnel/Store Keeper",
    },
    { value: "computer_operator", label: "Computer Operator" },

    {
      value: "area_of_job_health_care_medical",
      label: "Area of Job Health Care / Medical",
    },
    { value: "care_support_worker", label: "Care Support Worker" },
    { value: "nurse", label: "Nurse" },
    { value: "pharmacist", label: "Pharmacist" },

    {
      value: "area_of_job_hotel_hospitality",
      label: "Area of Job Hotel & Hospitality",
    },
    {
      value: "western_italian_chinese_food_production",
      label:
        "Western, Italian, Chinese food production (Cooking and preservation)",
    },
    {
      value: "food_beverage_service",
      label: "Food & Beverage Service (Waiter, Bartender)",
    },
    {
      value: "front_office_management",
      label: "Front Office Management (Receptionist call operator)",
    },
    {
      value: "room_boy_laundry_man_cleaners",
      label: "Room Boy/Laundry Man/Cleaners",
    },

    { value: "area_of_job_security", label: "Area of Job Security" },
    { value: "security_guard", label: "Security Guard" },
    {
      value: "residential_private_security",
      label: "Residential/Private Security",
    },
    { value: "official_bank_security", label: "Official/Bank Security" },
    { value: "industrial_security", label: "Industrial Security" },

    {
      value: "area_of_job_cleaning_housekeeping",
      label: "Area of Job Cleaning & Housekeeping",
    },
    { value: "office_boy", label: "Office Boy" },
    {
      value: "cleaner_hospital_school_mosque_office",
      label: "Cleaner (Hospital, School, Mosque, Office etc.)",
    },
    { value: "housekeeper", label: "Housekeeper" },
    { value: "care_giver", label: "Care Giver" },
  ];
  const optionsTwo = [
    { value: "bsc_health_social_care", label: "BSc Health & Social Care" },
    { value: "ba_hons_global_business", label: "BA (Hons) Global Business" },
    {
      value: "bsc_hons_business_tourism_management",
      label: "BSc (Hons) Business and Tourism Management",
    },
    {
      value: "bsc_hons_accounting_financial_management",
      label: "BSc (Hons) Accounting & Financial Management",
    },
    {
      value: "ba_hons_business_enterprise",
      label: "BA (Hons) Business and Enterprise",
    },
    {
      value: "bsc_hons_construction_management",
      label: "BSc (Hons) Construction Management",
    },
    {
      value: "bsc_hons_health_wellbeing_social_care",
      label: "BSc (Hons) Health, Wellbeing and Social Care",
    },
    {
      value: "hnc_hnd_healthcare_practice_england",
      label: "HNC/HND in Healthcare Practice for England",
    },
    { value: "hnd_business", label: "HND in Business" },
    { value: "hnd_digital_technologies", label: "HND in Digital Technologies" },
    {
      value: "diploma_education_training_det",
      label: "Diploma in Education and Training DET",
    },
    { value: "business_top_up", label: "Business Top-up" },
    { value: "ma_international_business", label: "MA International Business" },
    {
      value: "certificate_higher_education_business_certhe",
      label: "Certificate of Higher Education in Business (CertHE)",
    },
    { value: "ba_hons_business_studies", label: "BA (Hons) Business Studies" },
    {
      value: "ba_hons_health_social_care",
      label: "BA (Hons) Health and Social Care",
    },
    {
      value: "bsc_hons_business_management",
      label: "BSc (Hons) Business Management",
    },
    {
      value: "fda_business_management_ncd",
      label: "FdA Business & Management (NCD)",
    },
    {
      value: "fdsc_health_social_care_ncd",
      label: "FdSc in Health & Social Care (NCD)",
    },
    {
      value: "ba_hons_business_management",
      label: "BA (HONS) BUSINESS MANAGEMENT",
    },
    {
      value: "ba_hons_hospitality_management",
      label: "BA (HONS) HOSPITALITY MANAGEMENT",
    },
    {
      value: "ba_hons_fashion_management_marketing",
      label: "BA (HONS) FASHION MANAGEMENT AND MARKETING",
    },
    { value: "ba_hons_fashion", label: "BA (HONS) FASHION" },
    {
      value: "ba_hons_fashion_media_promotion",
      label: "BA (HONS) FASHION MEDIA AND PROMOTION",
    },
    { value: "ba_hons_graphic_design", label: "BA (HONS) GRAPHIC DESIGN" },
    {
      value: "bsc_hons_business_human_resource_management",
      label: "BSc (Hons) Business and Human Resource Management",
    },
    {
      value: "bsc_business_hospitality_management",
      label: "BSc Business and Hospitality Management",
    },
    {
      value: "bsc_hons_business_healthcare_management",
      label: "BSc (HONS) Business and Healthcare Management",
    },
    { value: "bsc_hons_business_law", label: "BSc (Hons) Business and Law" },
    {
      value: "bsc_hons_business_event_management",
      label: "BSc (Hons) Business and Event Management",
    },
    {
      value: "bsc_hons_business_marketing",
      label: "BSc (Hons) Business and Marketing",
    },
    {
      value: "bsc_hons_finance_accounting",
      label: "BSc (Hons) Finance and Accounting",
    },
    {
      value: "bsc_hons_international_business_management",
      label: "BSc (Hons) International Business Management",
    },
    { value: "llb_hons_law", label: "LLB (Hons) Law" },
    { value: "llm_law", label: "LLM Law" },
    {
      value: "msc_accounting_financial_management",
      label: "MSc Accounting and Financial Management",
    },
    {
      value: "msc_international_hospitality_tourism_management",
      label: "MSc International Hospitality and Tourism Management",
    },
    {
      value: "msc_international_project_management",
      label: "MSc International Project Management",
    },
    {
      value: "msc_international_marketing",
      label: "MSc International Marketing",
    },
    {
      value: "mba_business_administration_international",
      label: "Master (MBA) of Business Administration- International",
    },
    {
      value: "mba_business_administration_health_social_care",
      label: "Master (MBA) of Business Administration- Health and Social Care",
    },
    {
      value: "msc_business_business_analytics",
      label: "MSc Business with Business Analytics",
    },
    {
      value: "msc_business_entrepreneurship",
      label: "MSc Business with Entrepreneurship",
    },
    {
      value: "msc_business_financial_management",
      label: "MSc Business with Financial Management",
    },
    {
      value: "msc_business_hospitality_tourism_management",
      label: "MSc Business with Hospitality and Tourism Management",
    },
    {
      value: "msc_business_human_resource_management",
      label: "MSc Business with Human Resource Management",
    },
    {
      value: "msc_business_international_management",
      label: "MSc Business with International Management",
    },
    {
      value: "msc_business_marketing_management",
      label: "MSc Business with Marketing Management",
    },
    { value: "msc_computer_technology", label: "MSc Computer and Technology" },
    { value: "msc_cyber_security", label: "MSc Cyber Security" },
    {
      value: "msc_cyber_security_technology",
      label: "MSc Cyber Security Technology",
    },
    { value: "msc_digital_marketing", label: "MSc Digital Marketing" },
    {
      value: "msc_international_project_management",
      label: "MSc International Project Management",
    },
  ];

  return (
    <div
      className="hero h-[75vh] drop-shadow-sm bg-center bg-no-repeat z-10"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1682515110/images/PrototypeBG_uvnuk6.png")`,
      }}
    >
      <div className="hero-content justify-around content-around flex-col lg:flex-row-reverse container">
        <div className="w-[10rem] sm:w-[20rem] md:w-[25rem]">
          <ReactSVG src="https://res.cloudinary.com/lsoe/image/upload/v1684180611/head_mam63h.svg" />
        </div>
        <div className="w-[20rem] sm:w-[30rem] md:w-[35rem]">
          <ReactSVG src="https://res.cloudinary.com/lsoe/image/upload/v1683746204/images/Emp_text_h525ht.svg" />
        </div>
      </div>
      <div class="mx-auto w-[25rem] mt-[30rem] p-5 rounded-lg drop-shadow-md bg-gray-100">
        <form className="py-2">
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            Search
          </label>
          <div class="relative">
            <Select
              options={optionsOne}
              placeholder="Job Area, e.g. Health Care"
              required
            />

            <Link to="/services-we-provide">
              <button
                type="submit"
                class="z-40 text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Find Jobs
              </button>
            </Link>
          </div>
        </form>

        <form className="py-2">
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            Search
          </label>
          <div class="relative">
            <Select
              options={optionsTwo}
              placeholder="Courses, e.g. LLB"
              required
            />

            <Link to="/university-courses">
              <button
                type="submit"
                class="z-40 text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Find Course
              </button>
            </Link>
          </div>
        </form>

        {/* 

        <form>
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            Search
          </label>
          <div class="relative">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>

            <input
              type="search"
              id="default-search"
              class="block p-4 pl-10 w-full text-sm text-gray-200 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Courses, e.g. LLB
              "
            />

            <Link to="/university-courses">
              <button
                type="submit"
                class="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Find Course
              </button>
            </Link>
          </div>
        </form> */}
      </div>
    </div>
  );
};

export default EmpoyementHubBanner;
