import React from "react";
import { Link } from "react-router-dom";

const CleaningHousekeeping = () => {
  return (
    <div className="card bg-gray-200 shadow-xl">
      <figure>
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1675790653/images/Civil_Arc_oriry8.png"
          alt="Cleaning House Keeping"
          className="max-w-sm"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title">Cleaning & Housekeeping</h2>
        <ul className="py-10">
          <li className="list-disc list-inside">Office Boy</li>
          <li className="list-disc list-inside">
            Cleaner (Hospital, School, Mosque, Office etc.)
          </li>
          <li className="list-disc list-inside">Housekeeper</li>
          <li className="list-disc list-inside">Care Giver</li>
        </ul>
        <div className="card-actions justify-center">
          <Link to="/find-a-job">
            <button className="btn btn-accent text-white">
              Submit Your Application
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CleaningHousekeeping;
