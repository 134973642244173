import React from "react";
import { Link } from "react-router-dom";

const Administration = () => {
  return (
    <div className="card bg-gray-200 shadow-xl">
      <figure>
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1675790653/images/Civil_Arc_oriry8.png"
          alt="Cleaning House Keeping"
          className="max-w-sm"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title">Administration</h2>
        <ul className="py-10">
          <li className="list-disc list-inside">Project Manager</li>
          <li className="list-disc list-inside">Public Relation Manager</li>
          <li className="list-disc list-inside">Chartered Accountants</li>
          <li className="list-disc list-inside">Accountant/Cashier</li>
          <li className="list-disc list-inside">Secretary/Executives</li>
          <li className="list-disc list-inside">Clerk/Typist/Receptionist</li>
          <li className="list-disc list-inside">
            Sales Personnel/Store Keeper
          </li>
          <li className="list-disc list-inside">Computer Operator</li>
        </ul>
        <div className="card-actions justify-center">
          <Link to="/find-a-job">
            <button className="btn btn-accent text-white">
              Submit Your Application
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Administration;
