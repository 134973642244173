import React, { useEffect, useState } from "react";

const CoursesList = () => {
  const [course, setCourse] = useState([]);

  useEffect(() => {
    fetch("./courselist.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCourse(data);
      });
  }, []);
  return (
    <div className="bg-white">
      <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl text-center pt-5">
        Courses
      </h2>
      <div className=" container grid grid-cols-2 md:grid-cols-4  gap-3 mx-auto py-10">
        {course.map((course) => (
          <div class="relative w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-blue-500"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>
            <div class="ml-6">
              <h4 class="font-bold text-blue-500">{course.degree}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesList;
