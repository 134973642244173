import React from "react";

const SlaveryPolicy = () => {
  return (
    <>
      <div className="flex items-center justify-center py-5 bg-gray-200">
        <div className="container">
          <div className="bg-white rounded-lg shadow-lg p-5">
            <p className="text-lg font-normal">
              <strong>Policy on Modern Slavery for LSOE Pvt.</strong>
              <br />
              <br />
              <strong>Introduction: </strong> LSOE Pvt. Ltd. is committed to
              preventing modern slavery and human trafficking in all its forms.
              The Company recognizes that it has a responsibility to take
              effective steps to ensure that modern slavery does not take place
              within its operations or supply chains. The purpose of this policy
              is to set out the Company's approach to preventing modern slavery
              and human trafficking.
              <br />
              <br />
              <strong>Scope:</strong> This policy applies to all employees of
              the Company, as well as any contractors, suppliers, or other third
              parties who may be engaged in providing goods or services to the
              Company.
              <br />
              <br />
              <strong>Policy Statement :</strong> The Company is committed to
              ensuring that there is no modern slavery or human trafficking in
              any part of its operations or supply chains. To this end, the
              Company will:
              <br />
              <br />
              Comply with all applicable laws and regulations relating to modern
              slavery and human trafficking.
              <br />
              <br />
              Ensure that all employees, contractors, suppliers, and other third
              parties who are engaged in providing goods or services to the
              Company are aware of the Company's policy on modern slavery and
              human trafficking.
              <br />
              <br />
              Conduct due diligence on all suppliers and contractors to assess
              the risk of modern slavery in their operations and supply chains.
              <br />
              <br />
              Take appropriate steps to address any instances of modern slavery
              or human trafficking that may be identified.
              <br />
              <br />
              Provide training to employees, contractors, suppliers, and other
              third parties on how to recognize and report instances of modern
              slavery or human trafficking.
              <br />
              <br />
              Regularly review and update this policy to ensure that it remains
              effective and relevant to the Company's operations.
              <br />
              <br />
              Reporting Suspected Modern Slavery or Human Trafficking
              <br />
              <br />
              The Company encourages anyone who suspects that modern slavery or
              human trafficking is taking place within the Company's operations
              or supply chains to report their concerns to the Company's
              designated whistleblowing channel. The Company will investigate
              all reports of suspected modern slavery or human trafficking and
              will take appropriate action where necessary.
              <br />
              <br />
              <strong>Conclusion: </strong> The Company is committed to
              preventing modern slavery and human trafficking in all its forms.
              The Company will continue to review and improve its policies and
              procedures to ensure that it remains effective in identifying and
              addressing instances of modern slavery and human trafficking
              within its operations and supply chains.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlaveryPolicy;
