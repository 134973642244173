import React from "react";
import CountUp from "react-countup";

const Counter = () => {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 my-10 mx-10">
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-slate-100 drop-shadow-md">
          <div className="card-body text-center">
            <h2 className="text-secondary font-bold text-[3rem]">
              <CountUp end={2013} duration={3} />
            </h2>
            <p className="text-black font-medium">
              Active since, We are providing career guideline,
            </p>
          </div>
        </div>
        {/* card one end   */}
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-slate-100 drop-shadow-md">
          <div className="card-body text-center">
            <h2 className="text-secondary font-bold text-[3rem]">
              <span>
                <CountUp end={99} duration={3} />
              </span>
              %
            </h2>
            <p className="text-black font-medium">
              of successful international students progress to their chosen
              university
            </p>
          </div>
        </div>
        {/* card one end   */}
        {/* card one start  */}
        <div className="card lg:max-w-lg bg-slate-100 drop-shadow-md">
          <div className="card-body text-center">
            <h2 className="text-secondary font-bold text-[3rem]">
              <span>
                <CountUp end={90} duration={3} />
              </span>
              +
            </h2>
            <p className="text-black font-medium">
              Universities and Schools in Europe, UK, USA, Canada, Australia and
              New Zealand
            </p>
          </div>
        </div>
        {/* card one end   */}
      </div>
    </>
  );
};

export default Counter;
