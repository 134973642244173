import React from "react";
import { useEffect } from "react";
import EmploymentNav from "../../components/shared/header/EmploymentNav";
import EmpoyementHubBanner from "../../components/EmpoyementHubCompo/EmpoyementHubBanner";
import TrendingJobsCompo from "../../components/EmpoyementHubCompo/TrendingJobsCompo";
import SupportEMP from "../../components/EmpoyementHubCompo/SupportEMP";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import LookingInspiration from "../../components/EmpoyementHubCompo/LookingInspiration";
import HBottomArea from "../../components/EmpoyementHubCompo/HBottomArea";

const Home = () => {
  useEffect(() => {
    document.title = "Recruitment and Training on LSOE - LSOE Hiring Nexus";
  }, []);
  return (
    <div className="bg-base-100">
      <EmploymentNav />
      <EmpoyementHubBanner />
      <HBottomArea />
      <TrendingJobsCompo />
      <SupportEMP />
      <LookingInspiration />
      <EmploymentFooter />
    </div>
  );
};

export default Home;
