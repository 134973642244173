import React from "react";
import UniversityAndCoursesBanner from "../../components/UniversityAndCoursesCompo/UniversityAndCoursesBanner";
import UniList from "../../components/UniversityAndCoursesCompo/UniList";
import CoursesList from "../../components/UniversityAndCoursesCompo/CoursesList";
import EmploymentFooter from "../../components/shared/Footer/EmploymentFooter";
import EmploymentNav from "../../components/shared/header/EmploymentNav";

const UniversityAndCourses = () => {
  return (
    <div>
      <EmploymentNav />
      <UniversityAndCoursesBanner />
      <UniList />
      <CoursesList />
      <EmploymentFooter />
    </div>
  );
};

export default UniversityAndCourses;
