import React from "react";

const CareerHubCompoBanner = () => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2"
      style={{
        backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1678912515/BG1_roq4o4.jpg")`,
      }}
    >
      <div className="p-10 content-center">
        <h1 className="text-3xl font-bold text-center">
          Empower Your Future: Book a Session with LSOE Career Advisor
        </h1>
        <p className="py-6 text-center">
          Unlock boundless opportunities with LSOE Hiring Nexus's Career
          Advisors. Secure your slot now for tailored guidance on skills
          training and navigating the job market. From enhancing your
          application to crafting a robust career plan, we're here to propel you
          towards success. Let's uncover your strengths, passions, and
          aspirations to forge a fulfilling career path. Book your session today
          and embark on a transformative journey towards academic and
          professional excellence.
        </p>{" "}
      </div>
      <div className="">
        <img
          src="https://res.cloudinary.com/lsoe/image/upload/v1714732447/images/g50kfvtrm7kiufomon6d.png"
          className="w-[100%]  h-full rounded-md"
          alt="University Admission"
        />
      </div>
    </div>
    // <div
    //   className="hero h-[70vh] bg-base-100"
    //   style={{
    //     backgroundImage: `url("https://res.cloudinary.com/lsoe/image/upload/v1678912515/BG1_roq4o4.jpg")`,
    //   }}
    // >
    //   <div className="hero-content flex-col lg:flex-row-reverse">
    //     <img
    //       src="https://res.cloudinary.com/lsoe/image/upload/v1714732447/images/g50kfvtrm7kiufomon6d.png"
    //       className="max-sm w-[45%] rounded-lg shadow-lg"
    //       alt="University Admission"
    //     />
    //     <div>
    //       <h1 className="text-3xl font-bold">
    //         Empower Your Future: Book a Session with LSOE Career Advisor
    //       </h1>
    //       <p className="py-6">
    //         Unlock boundless opportunities with LSOE Hiring Nexus's Career
    //         Advisors. Secure your slot now for tailored guidance on skills
    //         training and navigating the job market. From enhancing your
    //         application to crafting a robust career plan, we're here to propel
    //         you towards success. Let's uncover your strengths, passions, and
    //         aspirations to forge a fulfilling career path. Book your session
    //         today and embark on a transformative journey towards academic and
    //         professional excellence.
    //       </p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CareerHubCompoBanner;
