import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Testing from "./components/shared/Testing";
import AboutAdmissionHub from "./pages/AboutAdmissionHub/AboutAdmissionHub";
import AboutEmploymentHub from "./pages/AboutEmploymentHub/AboutEmploymentHub";
import AboutUs from "./pages/AboutUs/AboutUs";
import ApplyNowForAssessment from "./pages/ApplyNowForAssessment/ApplyNowForAssessment";
import Login from "./pages/Auth/Login";
import PasswordReset from "./pages/Auth/PasswordReset";
import Registration from "./pages/Auth/Registration";
import RequireAdmin from "./pages/Auth/RequireAdmin";
import RequireAgent from "./pages/Auth/RequireAgent";
import RequireAuth from "./pages/Auth/RequireAuth";
import BecameATeacher from "./pages/BecameATeacher/BecameATeacher";
import CareerHub from "./pages/CareerHub/CareerHub";
import ContactAdmissionHub from "./pages/ContactAdmissionHub/ContactAdmissionHub";
import ContactEmploymentHub from "./pages/ContactEmploymentHub/ContactEmploymentHub";
import ContactLSOE from "./pages/ContactLSOE/ContactLSOE";
import AddAdmin from "./pages/Dashboard/AddAdmin/AddAdmin";
import AddAgent from "./pages/Dashboard/AddAgent/AddAgent";
import AgentRequests from "./pages/Dashboard/AgentRequests/AgentRequests";
import AllUsers from "./pages/Dashboard/AllUsers/AllUsers";
import Dashboard from "./pages/Dashboard/Dashboard";
import IndexAdminDashboard from "./pages/Dashboard/IndexAdminDashboard";
import ManageApplications from "./pages/Dashboard/ManageApplications/ManageApplications";
import MyAppointment from "./pages/Dashboard/MyAppointments/MyAppointment";
import MyHistory from "./pages/Dashboard/MyHistory/MyHistory";
import MyReview from "./pages/Dashboard/MyReview/MyReview";
import DashboardAgent from "./pages/DashboardAgent/DashboardAgent";
import IndexAgentDashboard from "./pages/DashboardAgent/IndexAgentDashboard";
import Employment from "./pages/Employment/Employment";
import EnglishSchool from "./pages/EnglishSchool/EnglishSchool";
import FindAJob from "./pages/FindAJob/FindAJob";
import FindATalent from "./pages/FindATalent/FindATalent";
import ModernSlaveryPolicy from "./pages/ModernSlaveryPolicy/ModernSlaveryPolicy";
import NotFound from "./pages/NotFound/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RecruitmentProcess from "./pages/RecruitmentProcess/RecruitmentProcess";
import ServicesWeProvide from "./pages/ServicesWeProvide/ServicesWeProvide";
import StartLearning from "./pages/StartLearning/StartLearning";
import StudentAccommodation from "./pages/StudentAccommodation/StudentAccommodation";
import StudentFinancialInformation from "./pages/StudentFinancialInformation/StudentFinancialInformation";
import StudentVisaAdvice from "./pages/StudentVisaAdvice/StudentVisaAdvice";
import UniversityAdmission from "./pages/UniversityAdmission/UniversityAdmission";
import ScrollToTop from "react-scroll-to-top";
import SocialLinks from "./pages/SocialLinks/SocialLinks";
import BDBanks from "./pages/TempBlog/BDBanks/BDBanks";
import NigeriaBanks from "./pages/TempBlog/NigeriaBanks/NigeriaBanks";
import SriLankaBanks from "./pages/TempBlog/SriLankaBanks/SriLankaBanks";
import ApplyNowForAgent from "./pages/ApplyNowForAgent/ApplyNowForAgent";
import AllApplications from "./pages/DashboardAgent/AllApplications/AllApplications";
import DraftApplications from "./pages/DashboardAgent/DraftApplications/DraftApplications";
import SubmittedApplications from "./pages/DashboardAgent/SubmittedApplications/SubmittedApplications";
import AssessmentApplications from "./pages/DashboardAgent/Assessment/AssessmentApplications";
import EnrolledApplications from "./pages/DashboardAgent/Enrolled/EnrolledApplications";
import RejectedApplications from "./pages/DashboardAgent/Rejected/RejectedApplications";
import ManageSingleApplication from "./pages/Dashboard/ManageApplications/ManageSingleApplication";
import SingleAgentApplication from "./pages/Dashboard/AgentRequests/SingleAgentApplication";
import SingleStudentInfo from "./pages/DashboardAgent/AllApplications/SingleStudentInfo";
import ApplyForAssLocal from "./pages/ApplyForAssLocal/ApplyForAssLocal";
import InternatioanlStudent from "./pages/ApplyNowLead/InternationalStudent/InternatioanlStudent";
import UKEUStudent from "./pages/ApplyNowLead/UKEUStudent/UKEUStudent";
import ApplyNow from "./pages/ApplyNowLead/ApplyNow";
import Home from "./pages/Home/Home";
import UniversityAndCourses from "./pages/UniversityAndCourses/UniversityAndCourses";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="password-reset" element={<PasswordReset />} />
        <Route path="registration" element={<Registration />} />
        <Route path="apply-now" element={<ApplyNow />} />
        <Route path="apply-now-int" element={<InternatioanlStudent />} />
        <Route path="apply-now-uk-eu" element={<UKEUStudent />} />

        <Route path="university-admission" element={<UniversityAdmission />} />
        <Route
          path="become-an-agent"
          element={
            <RequireAuth>
              <ApplyNowForAgent />
            </RequireAuth>
          }
        />
        <Route
          path="apply-now-for-assessment"
          element={
            <RequireAuth>
              <ApplyNowForAssessment />
            </RequireAuth>
          }
        />
        <Route
          path="local-file-assessment"
          element={
            <RequireAuth>
              <ApplyForAssLocal />
            </RequireAuth>
          }
        />

        <Route path="student-visa-advice" element={<StudentVisaAdvice />} />
        <Route
          path="student-financial-information"
          element={<StudentFinancialInformation />}
        />
        <Route
          path="student-accommodation"
          element={<StudentAccommodation />}
        />

        <Route path="testing" element={<Testing />} />
        <Route path="university-courses" element={<UniversityAndCourses />} />
        <Route path="english-school" element={<EnglishSchool />} />
        <Route path="start-learning" element={<StartLearning />} />
        <Route path="became-a-teacher" element={<BecameATeacher />} />
        <Route path="employment" element={<Employment />} />
        <Route path="find-a-talent" element={<FindATalent />} />
        <Route
          path="find-a-job"
          element={
            <RequireAuth>
              <FindAJob />
            </RequireAuth>
          }
        />
        <Route path="career-hub" element={<CareerHub />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="about-admission-hub" element={<AboutAdmissionHub />} />
        <Route
          path="about-lsoe-hiring-nexus"
          element={<AboutEmploymentHub />}
        />
        <Route path="recruitment-process" element={<RecruitmentProcess />} />
        <Route path="services-we-provide" element={<ServicesWeProvide />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="modern-slavery-policy" element={<ModernSlaveryPolicy />} />
        <Route path="contact-us" element={<ContactLSOE />} />
        <Route
          path="ukba-approved-banks-financial-institutions-in-bangladesh"
          element={<BDBanks />}
        />
        <Route
          path="guide-to-ukba-approved-banks-and-financial-institutions-in-nigeria"
          element={<NigeriaBanks />}
        />
        <Route
          path="ukba-approved-banks-financial-institutions-in-sri-lanka-a-complete-guide"
          element={<SriLankaBanks />}
        />
        <Route
          path="contact-london-school-of-excellence"
          element={<ContactAdmissionHub />}
        />
        <Route
          path="contact-lsoe-hiring-nexus"
          element={<ContactEmploymentHub />}
        />
        <Route path="social-links" element={<SocialLinks />} />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/application/:id"
          element={
            <RequireAdmin>
              <ManageSingleApplication />
            </RequireAdmin>
          }
        />
        <Route
          path="/student-information/:id"
          element={
            <RequireAgent>
              <SingleStudentInfo />
            </RequireAgent>
          }
        />
        <Route
          path="/agent-request/:id"
          element={
            <RequireAdmin>
              <SingleAgentApplication />
            </RequireAdmin>
          }
        />

        {/* Admin Dashboard Route Starts here */}
        <Route
          path="dashboard"
          element={
            <RequireAdmin>
              <Dashboard />
            </RequireAdmin>
          }
        >
          <Route index element={<IndexAdminDashboard />} />
          <Route
            index
            path="admin-overview"
            element={<IndexAdminDashboard />}
          />
          <Route path="manage-applications" element={<ManageApplications />} />

          <Route path="agent-requests" element={<AgentRequests />} />
          <Route path="add-admin" element={<AddAdmin />} />
          <Route path="add-agent" element={<AddAgent />} />
          <Route path="all-users" element={<AllUsers />} />
          <Route path="my-appointments" element={<MyAppointment />} />
          <Route path="reivew" element={<MyReview />} />
          <Route path="history" element={<MyHistory />} />
        </Route>
        {/* Dashboard Route End here */}

        {/* Agent Dashboard Route Starts here */}
        <Route
          path="agent-dashboard"
          element={
            <RequireAgent>
              <DashboardAgent />
            </RequireAgent>
          }
        >
          <Route index element={<IndexAgentDashboard />} />
          <Route
            index
            path="agent-overview"
            element={<IndexAgentDashboard />}
          />
          <Route path="all-applications" element={<AllApplications />} />
          <Route path="drafts-applications" element={<DraftApplications />} />
          <Route
            path="submitted-applications"
            element={<SubmittedApplications />}
          />
          <Route
            path="assessment-applications"
            element={<AssessmentApplications />}
          />
          <Route
            path="enrolled-applications"
            element={<EnrolledApplications />}
          />
          <Route
            path="rejected-applications"
            element={<RejectedApplications />}
          />
        </Route>
        {/*Agent Dashboard Route End here */}
      </Routes>
      <ToastContainer />
      <ScrollToTop
        smooth
        component={
          <p
            style={{
              color: "#0EA5E9",
              fontWeight: "bold",
            }}
          >
            Top
          </p>
        }
      />
    </>
  );
}

export default App;
