import React from "react";

const UniList = () => {
  return (
    <div className="bg-white">
      <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl text-center pt-5">
        Universities
      </h2>
      <div className=" container grid grid-cols-2 md:grid-cols-4  gap-3 mx-auto py-10">
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">
              Anglia Ruskin University (ARU)
            </h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Global Banking School (GBS)</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">London School of Commerce</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Elizabeth School of London</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">
              London School of Contemporary Arts
            </h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">
              London Metropolitan University QA
            </h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Ulster University</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Solent University</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Northumbria University</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">University of Roehampton</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Cecos College</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Apex College</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">Middlesex University</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>

        {/* single card  */}
        <div className="card  bg-gray-200 text-gray-900">
          <div className="card-body">
            <h2 className="card-title mx-auto">University of Cumbria</h2>

            {/* <div className="card-actions justify-end">
              <button className="btn">Buy Now</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniList;
